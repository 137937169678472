import { Box, Button, Drawer, Hidden, List, ListSubheader, Typography } from '@material-ui/core';
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import 'firebase/database';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink, matchPath, useHistory, useLocation } from 'react-router-dom';
import validateCompanySubscribed from '../../utils/validatePayments';
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import { PATH_DASHBOARD, PATH_DOCS } from '../../routes/paths';
import MenuLinks, { ICONS } from './SidebarConfig';
import SidebarItem from './SidebarItem';

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

// const AccountStyle = styled('div')(({ theme }) => ({
//   display: 'flex',
//   alignItems: 'center',
//   padding: theme.spacing(2, 2.5),
//   margin: theme.spacing(1, 2.5, 1),
//   borderRadius: theme.shape.borderRadiusSm,
//   backgroundColor: theme.palette.grey[500_12],
// }));

const DocStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(2.5),
  borderRadius: theme.shape.borderRadiusMd,
  backgroundColor:
    theme.palette.mode === 'light' ? alpha(theme.palette.primary.main, 0.08) : theme.palette.primary.lighter,
}));

function reduceChild({ array, item, pathname, level }) {
  const key = `${item.href}-${level}`;

  if (item.items) {
    const match = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    return [
      ...array,
      <SidebarItem
        key={key}
        level={level}
        icon={item.icon}
        info={item.info}
        href={item.href}
        title={item.title}
        open={Boolean(match)}
      >
        {renderSidebarItems({
          pathname,
          level: level + 1,
          items: item.items,
        })}
      </SidebarItem>,
    ];
  }
  return [
    ...array,
    <SidebarItem key={key} level={level} href={item.href} icon={item.icon} info={item.info} title={item.title} />,
  ];
}

function renderSidebarItems({ items, pathname, level = 0 }) {
  return <List disablePadding>{items.reduce((array, item) => reduceChild({ array, item, pathname, level }), [])}</List>;
}

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

const listOfPermissionsByType = {
  Legal: ['dashboard', 'administracion', 'contabilidad', 'legal', 'operaciones', 'comunicaciones', 'IA', 'whatsapp'],
  Contable: ['dashboard', 'administracion', 'contabilidad', 'legal', 'e-commerce', 'comunicaciones', 'IA', 'whatsapp'],
  Medico: ['dashboard', 'administracion', 'contabilidad', 'e-commerce', 'comunicaciones', 'IA', 'whatsapp'],
  eCommerce: ['dashboard', 'administracion', 'contabilidad', 'e-commerce', 'comunicaciones', 'IA', 'whatsapp'],
  Operaciones: ['dashboard', 'administracion', 'contabilidad', 'operaciones', 'comunicaciones', 'IA', 'whatsapp'],
  Otros: ['dashboard', 'administracion', 'contabilidad', 'operaciones', 'comunicaciones', 'IA', 'whatsapp'],
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const history = useHistory();
  const { myProfile, userProfile } = useSelector((state) => state.user);
  const { currentCompany, currentBranch } = useSelector((state) => state.company);

  // const [isCompanySubscribed, setIsCompanySubscribed] = useState(true);
  const [showSidebar, setShowSidebar] = useState(false);

  const redirectPaymentPage = () => {
    history.push(`/dashboard/payment`);
  };

  const redirectDashboardPage = () => {
    history.push(`/dashboard/user/profile`);
  };

  const validateGuestPath = () => {
    const isOwner = myProfile?.companies ? myProfile?.companies[currentCompany?.id]?.role === 'owner' || false : false;
    return !isOwner && String(pathname).includes(PATH_DASHBOARD.payment.root);
  };

  const isValidPath = (pathname) =>
    pathname === PATH_DASHBOARD.general.general ||
    // pathname === PATH_DASHBOARD.general.organization.root ||
    pathname === PATH_DASHBOARD.general.organization.create ||
    pathname === PATH_DASHBOARD.general.organization.edit ||
    pathname === PATH_DASHBOARD.general.home ||
    pathname === PATH_DASHBOARD.user.profile ||
    String(pathname).includes(PATH_DASHBOARD.payment.root) ||
    String(pathname).includes(PATH_DASHBOARD.whatsappDefault.root) ||
    String(pathname).includes(PATH_DASHBOARD.setup.root);

  useEffect(() => {
    const fetchSubscriptionValidation = async () => {
      console.log('currentCompany', currentCompany);
      console.log('currentCompany.subscription', currentCompany.subscription);
      const isCompanySubscribedTemp = await validateCompanySubscribed(
        currentCompany.subscription,
        currentCompany,
        userProfile
      );

      // setIsCompanySubscribed(isCompanySubscribedTemp);
      setShowSidebar(currentBranch.id && isCompanySubscribedTemp);
    };

    fetchSubscriptionValidation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBranch, currentCompany]);

  const validateType = (list, subheader) => {
    // console.log('currentBranch.type', currentBranch.type);
    // console.log('subheader', subheader);
    const type = currentBranch.type || 'Contable';
    const validation = listOfPermissionsByType[type].includes(subheader);
    // console.log('validation', validation);

    return list.subheader === subheader && validation;
  };

  const validateSuperAdmin = (list, subheader) => {
    const validation = myProfile?.isSuperAdmin;
    return list.subheader === subheader && validation;
  };

  const fetchSubscriptionValidation = async () => {
    console.log('currentCompany', currentCompany);
    console.log('currentCompany.subscription', currentCompany.subscription);
    const isCompanySubscribedTemp = await validateCompanySubscribed(
      currentCompany.subscription,
      currentCompany,
      userProfile
    );

    // setIsCompanySubscribed(isCompanySubscribedTemp);
    setShowSidebar(currentBranch.id && isCompanySubscribedTemp);
    return isCompanySubscribedTemp;
  };

  useEffect(() => {
    if (isOpenSidebar && onCloseSidebar) {
      onCloseSidebar();
    }

    if (validateGuestPath()) {
      redirectDashboardPage();
    }

    const isCompanySubscribed = fetchSubscriptionValidation();
    console.log('isCompanySubscribed', isCompanySubscribed);
    if (!isCompanySubscribed && !isValidPath(pathname)) {
      redirectPaymentPage();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderListItems = (list) => (
    <List
      disablePadding
      // key={`item-${index}`}
      subheader={
        <ListSubheader
          disableSticky
          disableGutters
          sx={{
            mt: 3,
            mb: 2,
            pl: 5,
            color: 'text.primary',
            typography: 'overline',
          }}
        >
          {list.subheader}
        </ListSubheader>
      }
    >
      {renderSidebarItems({
        items: list.items,
        pathname,
      })}
    </List>
  );

  const renderContent = (
    <Scrollbar>
      <Box sx={{ px: 2.5, py: 3 }}>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
      </Box>

      {renderSidebarItems({
        items: [
          {
            title: 'Home',
            href: PATH_DASHBOARD.general.home,
            icon: ICONS.home,
          },
          {
            title: 'Mi Perfil',
            href: PATH_DASHBOARD.user.profile,
            icon: ICONS.user,
          },
          {
            title: 'Blog',
            href: PATH_DASHBOARD.setup.bloguser.root,
            icon: ICONS.page,
          },
          {
            title: 'Notas',
            href: PATH_DASHBOARD.setup.texNote.root,
            icon: ICONS.page,
          },
        ],
        pathname,
      })}

      {/* {MenuLinks.map(
        (list, index) => (list.subheader === 'dashboard' || validateSuperAdmin(list, 'setup')) && renderListItems()
      )} */}

      {/* render dashboard */}

      {MenuLinks.map((list) =>
        validateType(list, 'dashboard') ||
        validateType(list, 'administracion') ||
        validateType(list, 'contabilidad') ||
        validateType(list, 'e-commerce') ||
        validateType(list, 'comunicaciones') ||
        validateType(list, 'IA') ||
        validateType(list, 'legal') ||
        validateType(list, 'operaciones') ||
        validateType(list, 'whatsapp') ||
        validateType(list, 'pagos') ? (
          <>{showSidebar && renderListItems(list)}</>
        ) : (
          <></>
        )
      )}

      {/* render setup */}
      {validateSuperAdmin(MenuLinks[1], 'setup') && renderListItems(MenuLinks[1])}

      <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <DocStyle>
          <Box component="img" alt="doc" src="/static/icons/ic_doc.svg" sx={{ width: 36, height: 36, mb: 2 }} />
          <Typography gutterBottom variant="subtitle1" sx={{ color: 'grey.800' }}>
            Hi,
            {`${myProfile?.firstName} ${myProfile?.lastName}`}
          </Typography>
          <Typography variant="body2" sx={{ mb: 2, color: 'grey.600' }}>
            Need help?
            <br /> Please check our docs
          </Typography>
          <Button fullWidth to={PATH_DOCS.root} variant="contained" component={RouterLink}>
            Documentation
          </Button>
        </DocStyle>
      </Box>
    </Scrollbar>
  );

  return (
    <RootStyle>
      <Hidden lgUp>
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: { width: DRAWER_WIDTH, bgcolor: 'background.default' },
          }}
        >
          {renderContent}
        </Drawer>
      </Hidden>
    </RootStyle>
  );
}
